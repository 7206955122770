<template>
  <el-container style="height: 100vh">
    <el-header style="padding: 0">
      <BaseHeader back-path="/" :contain-avatar="false">
        <template v-slot:left>
          <span>网页不存在</span>
        </template>
      </BaseHeader>
    </el-header>
    <el-main>
      <el-empty
        :image="NotFoundImg"
        :image-size="300"
        description="当前访问的网页不存在！" />
    </el-main>
  </el-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import NotFoundImg from '@/assets/not_found.png'

export default {
  name: 'NotFound',
  components: {
    BaseHeader
  },
  data () {
    return {
      NotFoundImg
    }
  }
}
</script>

<style scoped>

</style>
